<template>
  <div class="d-flex h-100">
    <div class="card flex-grow-1 flex-fill">
      <div class="card-body m-3" v-if="!loading">
        <p class="mb-2 h4">
          <b>Availability</b>
        </p>
        <b-table
          ref="table"
          striped
          :fields="fields"
          :items="availability"
          stacked="md"
          small
          selectable
          class="mt-4"
          bordered
          sort-icon-left
          @row-clicked="productClassClicked"
        >
        </b-table>
      </div>
      <div v-else class="text-center mt-6">
        <span
          class="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        ></span>
      </div>
    </div>
  </div>
</template>

<script>
import AdminDashboardService from "@/services/admin/AdminDashboardService.js";
import moment from "moment";

export default {
  name: "AdminProductClassAvailability",
  props: {
    locationId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      loading: false,
      availability: [],
      fields: [
        { key: "name", sortable: true, label: "Product Class Name" },
        { key: "numberAvailable", label: "Available" }
      ]
    };
  },
  methods: {
    async getProductClassAvailability() {
      this.$emit("loading", true);
      this.loading = true;
      const service = new AdminDashboardService(this.tenantId);
      let currentDateString = moment().format("MM/DD/YYYY");
      const response = await service.getProductClassAvailability(
        this.locationId,
        currentDateString
      );
      try {
        if (response) {
          this.availability = response.data.map(x => {
            return {
              ...x,
              name: `${x.name} - P${(x.productClassificationId + "").padStart(
                3,
                "0"
              )}`
            };
          });
        } else {
          this.$store.commit("alert/setErrorAlert", {
            type: "alert-danger",
            message: "Something went wrong...",
            layer: "admin"
          });
        }
      } catch (ex) {
        this.$store.commit("alert/setErrorAlert", {
          type: "alert-danger",
          message: "Something went wrong...",
          layer: "admin"
        });
      } finally {
        this.$emit("loading", false);
        this.loading = false;
      }
    },
    productClassClicked(e) {
      this.$store.commit("search/setSelectedProductClassifications", [
        e.productClassificationId
      ]);
      this.$router
        .push(`/admin/reservation-add/${this.locationId}`)
        .catch(() => {});
    }
  },
  computed: {
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  watch: {
    locationId() {
      this.getProductClassAvailability();
    }
  },
  created() {
    this.getProductClassAvailability();
  }
};
</script>

<style scoped>
.card {
  min-height: 200px;
}
</style>
