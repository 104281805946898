<script>
import { Bar } from "vue-chartjs";
import ChartJsPluginDataLabels from "chartjs-plugin-datalabels";

export default {
  name: "BarChart",
  extends: Bar,
  props: ["chartdata", "options"],
  mounted() {
    this.addPlugin(ChartJsPluginDataLabels);
    this.renderChart(this.chartdata, this.options);
  }
};
</script>
