<template>
  <div>
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <AdminCheckInOutTab
          @location-update="updateLocation"
          :loading="chartLoading && availLoading"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-md-4 col-sm-12">
        <AdminProductClassAvailability
          :locationId="selectedLocationId"
          @loading="setAvailLoading"
        />
      </div>
      <div class="col-md-8 col-sm-12">
        <div class="card">
          <div class="card-body m-3">
            <p class="mb-3 h4">
              <b>Activity and Occupancy</b>
            </p>
            <AdminDailyChart
              :locationId="selectedLocationId"
              v-if="selectedLocationId"
              @loading="setChartLoading"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <button
          type="button"
          class="btn btn-primary mr-2 my-1"
          @click="makeReservation"
          :disabled="disabled"
        >
          Make Reservation <i class="far fa-calendar-alt ml-1"></i>
        </button>
        <button
          type="button"
          class="btn btn-primary mr-2 my-1"
          @click="toPointOfSaleOrders"
        >
          POS Reservations <i class="far fa-calendar-alt ml-1"></i>
        </button>
        <button
          type="button"
          class="btn btn-primary mr-2 my-1"
          @click="goToCheckIn"
          :disabled="disabled"
        >
          CHECK IN
          <i class="fas fa-calendar-check ml-1" />
        </button>
        <button
          type="button"
          class="btn btn-primary my-1"
          @click="goToCheckOut"
          :disabled="disabled"
        >
          CHECK OUT
          <i class="fas fa-calendar-times ml-1" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import AdminDailyChart from "@/components/admin/dashboard/AdminDailyChart.vue";
import AdminCheckInOutTab from "@/components/admin/AdminCheckInOutTab.vue";
import AdminProductClassAvailability from "@/components/admin/AdminProductClassAvailability.vue";

export default {
  name: "AdminHome",
  title: "Admin - Home",
  components: {
    AdminCheckInOutTab,
    AdminDailyChart,
    AdminProductClassAvailability
  },
  data() {
    return {
      selectedLocationId: null,
      chartLoading: false,
      availLoading: false
    };
  },
  computed: {
    disabled() {
      return this.selectedLocationId === null;
    }
  },
  methods: {
    setChartLoading(e) {
      this.chartLoading = e;
    },
    setAvailLoading(e) {
      this.availLoading = e;
    },
    makeReservation() {
      if (!this.selectedLocationId) {
        return;
      }
      this.$router
        .push(`/admin/reservation-add/${this.selectedLocationId}`)
        .catch(() => {});
    },
    toPointOfSaleOrders() {
      this.$router.push(`/admin/pos-reservations`).catch(() => {});
    },
    goToCheckIn() {
      if (!this.selectedLocationId) {
        return;
      }
      this.$router
        .push(`/admin/bulk-check-in/${this.selectedLocationId}`)
        .catch(() => {});
    },
    goToCheckOut() {
      if (!this.selectedLocationId) {
        return;
      }
      this.$router
        .push(`/admin/bulk-check-out/${this.selectedLocationId}`)
        .catch(() => {});
    },
    updateLocation(e) {
      this.selectedLocationId = e;
    }
  },
  mounted() {
    this.$store.dispatch("search/clear");
  },
  beforeCreate() {
    this.$store.commit("auth/setLoading", false);
    this.$store.dispatch("search/clear");
    this.$store.dispatch("transaction/clearReservation");
    this.$store.dispatch("admin/clearSpotSearch");
    this.$store.dispatch("admin/clearClosureSearch");
    this.$store.dispatch("admin/clearAlertSearch");
    this.$store.dispatch("admin/clearReservationSearch");
    this.$store.dispatch("admin/clearCustomerSearch");
    this.$store.dispatch("admin/clearUserSearch");
  }
};
</script>
<style scoped>
.row2 {
  height: 500px;
}
</style>
