import AdminDashboardApi from "@/generatedapiclients/src/api/AdminDashboardApi.js";

export default class AdminDashboardService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._adminDashboardApi = new AdminDashboardApi();
  }

  async getProductClassAvailability(locationId, startDateString) {
    const response = await this._adminDashboardApi.v1TenantTenantIdAdminAdminDashboardProductclassavailabilityGet(
      this._tenantId,
      {
        locationId: locationId,
        startDateString: startDateString
      }
    );
    return response;
  }

  getDashboardChartData(locationId, startDateString) {
    return this._adminDashboardApi.v1TenantTenantIdAdminAdminDashboardDashboardChartLocationIdGet(
      locationId,
      this._tenantId,
      {
        startDateString
      }
    );
  }
}
