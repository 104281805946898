<template>
  <div class="card">
    <div class="card-body">
      <div class="m-3">
        <p class="mb-2 h4">
          <b>{{ today }}</b>
        </p>
        <div class="mb-3">
          <span v-if="locations">
            <label for="selectedLocation">Select My Park</label>
            <select
              class="form-control form-control-lg"
              id="selectedLocation"
              v-model="selectedLocation"
              :disabled="loading"
            >
              <option v-if="locations.length === 0" :value="null">
                No Parks Available
              </option>
              <option
                v-for="location in locations"
                :key="location.id"
                :value="location.id"
              >
                {{ location.longName }}
              </option>
            </select>
          </span>
          <span
            v-else
            class="spinner-border spinner-border-sm mx-3"
            role="status"
            aria-hidden="true"
          ></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import LocationService from "@/services/LocationService.js";
import checkPermissionMixin from "@/mixins/PermissionCheckMixin.js";

export default {
  name: "AdminCheckInOutTab",
  mixins: [checkPermissionMixin],
  props: {
    loading: Boolean
  },
  data() {
    return {
      locations: null,
      selectedLocation: null
    };
  },
  computed: {
    today() {
      return moment().format("dddd, MMMM D, YYYY");
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    }
  },
  methods: {
    initialize() {
      const locationService = new LocationService(this.tenantId);
      locationService.getAllLocations().then(response => {
        const locations = response.filter(x =>
          this.checkLocationPermission("ReservationManagementView", x.id)
        );
        this.locations = locations;
        this.selectedLocation = locations[0].id;
        this.updateLocation();
      });
    },
    updateLocation() {
      this.$emit("location-update", this.selectedLocation);
    }
  },
  watch: {
    selectedLocation() {
      this.updateLocation();
    }
  },
  created() {
    this.initialize();
  }
};
</script>
