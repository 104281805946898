/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import DashboardChartDataIEnumerableResponse from '../model/DashboardChartDataIEnumerableResponse';
import ProductClassificationAvailabilityIEnumerableResponse from '../model/ProductClassificationAvailabilityIEnumerableResponse';

/**
* AdminDashboard service.
* @module api/AdminDashboardApi
* @version v1
*/
export default class AdminDashboardApi {

    /**
    * Constructs a new AdminDashboardApi. 
    * @alias module:api/AdminDashboardApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.startDateString 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/DashboardChartDataIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminDashboardDashboardChartLocationIdGetWithHttpInfo(locationId, tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'locationId' is set
      if (locationId === undefined || locationId === null) {
        throw new Error("Missing the required parameter 'locationId' when calling v1TenantTenantIdAdminAdminDashboardDashboardChartLocationIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminDashboardDashboardChartLocationIdGet");
      }

      let pathParams = {
        'locationId': locationId,
        'tenantId': tenantId
      };
      let queryParams = {
        'startDateString': opts['startDateString']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = DashboardChartDataIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminDashboard/dashboard-chart/{locationId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} locationId 
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {String} opts.startDateString 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/DashboardChartDataIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminDashboardDashboardChartLocationIdGet(locationId, tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminDashboardDashboardChartLocationIdGetWithHttpInfo(locationId, tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.locationId 
     * @param {String} opts.startDateString 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/ProductClassificationAvailabilityIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminDashboardProductclassavailabilityGetWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminDashboardProductclassavailabilityGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
        'locationId': opts['locationId'],
        'startDateString': opts['startDateString']
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = ProductClassificationAvailabilityIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminDashboard/productclassavailability', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {Number} opts.locationId 
     * @param {String} opts.startDateString 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/ProductClassificationAvailabilityIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminDashboardProductclassavailabilityGet(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminDashboardProductclassavailabilityGetWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
