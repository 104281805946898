/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */

import ApiClient from '../ApiClient';

/**
 * The DashboardChartData model module.
 * @module model/DashboardChartData
 * @version v1
 */
class DashboardChartData {
    /**
     * Constructs a new <code>DashboardChartData</code>.
     * @alias module:model/DashboardChartData
     */
    constructor() { 
        
        DashboardChartData.initialize(this);
    }

    /**
     * Initializes the fields of this object.
     * This method is used by the constructors of any subclasses, in order to implement multiple inheritance (mix-ins).
     * Only for internal use.
     */
    static initialize(obj) { 
    }

    /**
     * Constructs a <code>DashboardChartData</code> from a plain JavaScript object, optionally creating a new instance.
     * Copies all relevant properties from <code>data</code> to <code>obj</code> if supplied or a new instance if not.
     * @param {Object} data The plain JavaScript object bearing properties of interest.
     * @param {module:model/DashboardChartData} obj Optional instance to populate.
     * @return {module:model/DashboardChartData} The populated <code>DashboardChartData</code> instance.
     */
    static constructFromObject(data, obj) {
        if (data) {
            obj = obj || new DashboardChartData();

            if (data.hasOwnProperty('date')) {
                obj['date'] = ApiClient.convertToType(data['date'], 'String');
            }
            if (data.hasOwnProperty('arrivals')) {
                obj['arrivals'] = ApiClient.convertToType(data['arrivals'], 'Number');
            }
            if (data.hasOwnProperty('departures')) {
                obj['departures'] = ApiClient.convertToType(data['departures'], 'Number');
            }
            if (data.hasOwnProperty('available')) {
                obj['available'] = ApiClient.convertToType(data['available'], 'Number');
            }
            if (data.hasOwnProperty('occupied')) {
                obj['occupied'] = ApiClient.convertToType(data['occupied'], 'Number');
            }
        }
        return obj;
    }


}

/**
 * @member {String} date
 */
DashboardChartData.prototype['date'] = undefined;

/**
 * @member {Number} arrivals
 */
DashboardChartData.prototype['arrivals'] = undefined;

/**
 * @member {Number} departures
 */
DashboardChartData.prototype['departures'] = undefined;

/**
 * @member {Number} available
 */
DashboardChartData.prototype['available'] = undefined;

/**
 * @member {Number} occupied
 */
DashboardChartData.prototype['occupied'] = undefined;






export default DashboardChartData;

