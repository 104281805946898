<template>
  <div class="small">
    <bar-chart
      :chartdata="chartdata"
      :options="options"
      v-if="loaded"
      :styles="chartstyle"
    ></bar-chart>
    <div v-else class="text-center mt-3">
      <span
        class="spinner-border spinner-border-sm"
        role="status"
        aria-hidden="true"
      ></span>
    </div>
  </div>
</template>

<script>
import BarChart from "@/components/admin/dashboard/BarChart.vue";
import AdminDashboardService from "@/services/admin/AdminDashboardService.js";
import moment from "moment";

export default {
  name: "AdminDailyChart",
  components: {
    BarChart
  },
  props: {
    locationId: Number
  },
  data() {
    return {
      loaded: false,
      serverData: [],
      lastHoveredIndex: null,
      options: {
        layout: {
          padding: {
            top: 30
          }
        },
        legend: {
          position: "bottom"
        },
        plugins: {
          display: "auto",
          datalabels: {
            color: "black",
            anchor: "end",
            align: "end",
            font: {
              weight: "bold"
            }
          }
        },
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
                precision: 0
              }
            }
          ],
          xAxes: [
            {
              gridLines: {
                display: true,
                color: "#999999"
              }
            }
          ]
        },
        responsive: true,
        maintainAspectRatio: false,
        onClick: this.clicked,
        tooltips: {
          callbacks: {
            label: (tooltipItem, chart) => {
              let seriesIndex = tooltipItem.index;
              let index = tooltipItem.datasetIndex;
              let value = chart.datasets[index].data[seriesIndex];
              let label = chart.datasets[index].label;
              //In order to get the specific bar that was clicked, we have to store the index when the mouse is hovering over the bar prior to clicking
              this.lastHoveredIndex = index;
              return label + " " + value;
            }
          }
        }
      }
    };
  },
  computed: {
    chartstyle() {
      return {
        height: "250px",
        position: "relative"
      };
    },
    tenantId() {
      return this.$store.getters["tenant/tenantId"];
    },
    chartdata() {
      return {
        labels: this.chartDataLabels,
        datasets: this.chartDatasets
      };
    },
    chartDataLabels() {
      let labels = this.serverData.map(d => d.date);
      return labels;
    },
    chartDatasets() {
      let datasets = [
        {
          label: "Arrivals",
          backgroundColor: "#e67e22",
          data: []
        },
        {
          label: "Departures",
          backgroundColor: "#1f618d",
          data: []
        },
        {
          label: "Available",
          backgroundColor: "#229954",
          data: []
        },
        {
          label: "Occupied",
          backgroundColor: "#c0392b",
          data: []
        }
      ];
      this.serverData.forEach(element => {
        datasets[0].data.push(element.arrivals);
        datasets[1].data.push(element.departures);
        datasets[2].data.push(element.available);
        datasets[3].data.push(element.occupied);
      });
      return datasets;
    }
  },
  methods: {
    loadData() {
      this.$emit("loading", true);
      this.loaded = false;
      const dashboardService = new AdminDashboardService(this.tenantId);
      let currentDateString = moment().format("MM/DD/YYYY");
      dashboardService
        .getDashboardChartData(this.locationId, currentDateString)
        .then(response => {
          this.serverData = response.data;
        })
        .finally(() => {
          this.$emit("loading", false);
          this.loaded = true;
        });
    },
    clicked(event, item) {
      let clickedBar = item[this.lastHoveredIndex];
      let dataIndex = clickedBar._index;
      let datasetIndex = clickedBar._datasetIndex;
      if (datasetIndex == 0) {
        this.arrivalsClickedRedirect(this.serverData[dataIndex].date);
      } else if (datasetIndex == 1) {
        this.departuresClickedRedirect(this.serverData[dataIndex].date);
      } else if (datasetIndex == 2) {
        this.availableClickedRedirect(this.serverData[dataIndex].date);
      }
    },
    arrivalsClickedRedirect(date) {
      this.$router
        .push(
          "/admin/reservation-search?locationId=" +
            this.locationId +
            "&arrivalDate=" +
            date
        )
        .catch(() => {});
    },
    departuresClickedRedirect(date) {
      this.$router
        .push(
          "/admin/reservation-search?locationId=" +
            this.locationId +
            "&departureDate=" +
            date
        )
        .catch(() => {});
    },
    availableClickedRedirect(date) {
      this.$router
        .push(
          "/admin/reservation-add/" + this.locationId + "?arrivalDate=" + date
        )
        .catch(() => {});
    }
  },
  watch: {
    locationId() {
      this.loadData();
    }
  },
  mounted() {
    this.loadData();
  }
};
</script>
